<template>
  <v-app>
  <div>

      <div  style="margin-top: 60px;display: flex; flex-direction: column; align-items: center">
        <div style="display: flex;justify-content:center">
          <img src="/logo2.jpg" style="width: 300px;">
        </div>
        <div class="mt-10" style="margin-top: 30px; max-width: 390px" >
          <v-card style="padding-top: 40px; margin: 10px" width="360">
            <ng-form :form="form" v-if="step == 1" >
              <v-container class="py-0" style="max-width: 600px">
              <v-row no-gutters>
                <ng-text
                    :form="form"
                    :options="form.getCtrolConfig('user')"
                    v-on:keyup.enter="recoverPasswd"
                />

                </v-row>
                <v-row>
                  <v-col style="display: flex; flex-direction: column; align-items: center">
                    <v-btn elevation="1" color="primary" :disabled="!form._valid" @click="recoverPasswd" class="mr-1" >
                    <v-icon class="mr-2">mdi-login</v-icon> Enviar
                  </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </ng-form>

            <div v-if="step == 2" style="padding: 10px">
              <div class="">Se ha enviado un código a su correo, para continuar por favor escribalo en el siguiente campo y de click en continuar.</div>
              <br />

              <v-text-field v-model="code" label="Código" outlined dense></v-text-field>
              <br />
              <v-btn :disabled="!code" @click="validateCode" color="primary">Continuar</v-btn>
            </div>

            <div v-if="step == 3" style="padding: 10px">
              <div class="">Escriba una contraseña segura que recuerde, use mayusculas, minusculas y números.</div>
              <br />

              <v-text-field v-model="passwd" label="Contraseña"  outlined
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
              ></v-text-field>
              <br />
              <v-btn :disabled="!passwd"  @click="changePasswd" color="primary">Cambiar Contraseña</v-btn>
            </div>
         </v-card>
        </div>
        
      
     </div>
  </div>
</v-app>
</template>

<script>
  import { NgForm, handler } from '@/components/gui/NgForm';
  
  export default {
    name: 'Login',
    data: () => ({
      form: null,
      passwd: '',
      code: '',
      hash: '',
      step: 1,
      show1: false,
      dialog: false

    }),
    created() {

      const configFormMpn = {
        "user": {
          "name": "user",
          "label": "Usuario/Correo",
          "validate": [ "required" ],
          "prepend-inner-icon": "mdi-card-account-details",
          "required": true,
          dense: false,
          outlined: false
        },
      };
      const form = new NgForm(configFormMpn, this)
      this.form = new Proxy(form, handler)
  
      this.form.init();
    },
    computed: {
      error () {
        return this.$store.getters.error
      },
    },
    methods: {
      onDismissed () {
        this.$store.dispatch('clearError')
      },
      recoverPasswd () {
        this.$http.post('api/send-code', { data: { email: this.form.user }}, this, false, false ).then( (res) => {
          if (res.success) {
            this.hash = res.hash;
            this.step = 2;
 
          } 
        }).catch((e) => {
        })
      },
      validateCode () {
        this.$http.post('validate-code', { data: { email: this.form.user, code: this.code, hash: this.hash }}, this, true, true ).then( (res) => {
          if (res.success) {
            this.step = 3;
 
          } 
        }).catch((e) => {
        })
      },
      changePasswd () {
        let data = {
          code: this.code,
          passwd: this.passwd,
          email: this.form.user,
          hash: this.hash
        }
        this.$http.post('change-passwd', { data }, this, true, false ).then( (res) => {
          if (res.success) {
            this.$store.commit('setMessage',  'La contraseña se cambio correctamente.')
            setTimeout(() => {
              this.$router.push('/') ;
            }, 2000)
            
          } 
        }).catch((e) => {
        })
      },

    }
  }
</script>
